<template>
  <div>
    <app-cells position="start">
      <app-button
        v-for="(file, index) of files"
        :key="index"
        size="link"
        :class="{'btn--loading': file.loader}"
        @click="onGetFile(file)"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>{{ file.name }}</span>
      </app-button>
    </app-cells>
    <app-grid class="grid--indent">
      <template #item-1>
        <app-form-group label="Направления" :indent="false">
          <v-select
            v-model="direction"
            :reduce="item => item.id"
            :options="directions_options"
            @input="getIntervals"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            placeholder="Выберите направление"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
        </app-form-group>
      </template>
      <template #item-2>
        <app-form-group label="Промежуток" :indent="false">
          <v-select
            v-model="time"
            :reduce="item => item.id"
            :options="time_options"
            @input="onGetGraduateList"
            :filterable="true"
            :clearable="false"
            :searchable="false"
            label="date"
            placeholder="Выберите промежуток"
            class="select"
            :readonly="!time_options.length"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.date }} {{ option.time_start }} - {{ option.time_end }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.date }} {{ option.time_start }} - {{ option.time_end }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
        </app-form-group>
      </template>
    </app-grid>
    <div v-if="graduate.length && time_options.length" class="table-enter">
      <div class="table-enter__header">
        <div class="table-enter__row">
          <div class="table-enter__col">Номер</div>
          <div v-for="item of graduate[2].grades" :key="item.expert_id" class="table-enter__col">{{ item.name }}</div>
        </div>
      </div>
      <div class="table-enter__body">
        <div class="table-enter__row" v-for="item of graduate" :key="item.number">
          <div class="table-enter__col">{{ item.number_name }}</div>
          <span v-for="(grade, index) of item.grades" :key="index" class="table-enter__col">
              <input type="number" v-model.number="grade.value" step="0.5" @change="onChangeGrade(item.number, grade.expert_id, grade.value)">
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDirections,
  getProgramInterval,
  getGraduateList,
  putGraduateList,
  getProgramScoresheetsZip,
  getProgramScoresheetsExcel
} from '@/http'
import {format, parse} from 'date-fns'
import ru from 'date-fns/locale/ru'

export default {
  name: 'GradesList',
  data() {
    return {
      directions_options: [],
      direction: null,
      time_options: [],
      time: null,
      graduate: [],
      files: [
        {
          name: 'Скачать оценочные листы (PDF)',
          name_file: 'Оценочные листы (PDF)',
          loader: false,
          action: getProgramScoresheetsZip,
          format: 'zip',
          pdf: true,
        },
        {
          name: 'Скачать оценочные листы (Word)',
          name_file: 'Оценочные листы (Word)',
          loader: false,
          action: getProgramScoresheetsZip,
          format: 'zip',
          pdf: false,
        },
        {
          name: 'Скачать среднюю оценку (Excel)',
          name_file: 'Средняя оценка',
          loader: false,
          action: getProgramScoresheetsExcel,
          format: 'xlsx',
          pdf: false,
        },
      ],
    }
  },
  created() {
    getDirections().then(res => { this.directions_options = res.data })
  },
  methods: {
    onGetFile(file) {
      file.loader = true
      file.action(file.pdf)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${file.name_file} ${format(new Date(), 'dd.MM.yyyy HH_mm')}.${file.format}`)
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          file.loader = false
        })
    },
    getIntervals() {
      this.time_options = []
      this.time = null
      this.graduate = []
      getProgramInterval({ direction: this.direction })
        .then(res => {
          this.time_options = res.data.map(item => ({
            ...item,
            date: format(parse(item.date, 'yyyy-MM-dd', new Date()), 'dd MMM',  { locale: ru }),
            time_start: item.time_start.substring(0, item.time_start.length - 3),
            time_end: item.time_end.substring(0, item.time_end.length - 3),
          }))
        })
    },
    onGetGraduateList() {
      getGraduateList(this.direction, this.time)
        .then(response => {
          this.graduate = response.data
        })
    },
    onChangeGrade(number, expert, grade) {
      putGraduateList(number, expert, {value: grade})
        .then(() => {
          this.onGetGraduateList()
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Оценка изменена'
          })
        })
    },
  }
}
</script>

<style scoped lang="sass">
.table-enter
  box-shadow: 0 4px 20px rgba(36, 81, 126, 0.2)
  border-radius: 10px 10px 0 0

.table-enter__header
  border-radius: 10px 10px 0 0
  background-color: #ebf2f9

  .table-enter__col
    color: $color-theme
    font-size: 16px
    font-weight: 600
    text-align: center

.table-enter__row
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr))

.table-enter__col
  padding: 15px
  font-size: 15px

  input
    width: 100%
    padding: 10px
    border: 1px solid transparent
    border-radius: 5px
    color: $color-text-base
    text-align: center
    transition: border-color 0.3s
    font-size: 16px

    &:active
      border-color: $color-theme

    @media(hover: hover)
      &:focus-visible
        border-color: $color-theme

.table-enter__body
  .table-enter__row
    border-bottom: 2px solid #ebf2f9

    &:last-child
      border: none

  .table-enter__col
    border-right: 2px solid #ebf2f9

    &:not(:first-child)
      display: flex
      align-items: center

    &:last-child
      border: none
</style>
